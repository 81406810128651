import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterModule, SnackbarComponent],
})
export class AppComponent {}
