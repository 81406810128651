import { ApplicationConfig } from '@angular/core';

import { appRoutes } from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import { provideRouter, TitleStrategy, withInMemoryScrolling } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from './shared/modules/loader/loader.interceptor';
import { BaseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { MfaApiService } from './features/mfa/mfa.service';
import { MerchantMfaApiService } from './features/mfa/merchant-mfa-api.service';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { MerchantTitleStrategy } from './core/services/merchant-title.strategy';

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: DEFAULT_DIALOG_CONFIG,
            useFactory: () => {
                const defaultConfig = new DialogConfig();
                defaultConfig.width = '540px';
                defaultConfig.maxWidth = '90%';
                return defaultConfig;
            },
        },
        { provide: MfaApiService, useExisting: MerchantMfaApiService },
        { provide: TitleStrategy, useClass: MerchantTitleStrategy },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(
            appRoutes,
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
            }),
        ),
    ],
};
