import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DEFAULT_DIALOG_CONFIG, DialogConfig, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { TitleStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from './shared/modules/loader/loader.interceptor';
import { BaseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { MfaApiService } from './features/mfa/mfa.service';
import { MerchantMfaApiService } from './features/mfa/merchant-mfa-api.service';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { MerchantTitleStrategy } from './core/services/merchant-title.strategy';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ClipboardModule,
        BrowserAnimationsModule,
        CommonModule,
        DialogModule,
        SnackbarComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: DEFAULT_DIALOG_CONFIG,
            useFactory: () => {
                const defaultConfig = new DialogConfig();
                defaultConfig.width = '540px';
                defaultConfig.maxWidth = '90%';
                return defaultConfig;
            },
        },
        { provide: MfaApiService, useExisting: MerchantMfaApiService },
        { provide: TitleStrategy, useClass: MerchantTitleStrategy },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
