import { CanMatchFn, Router } from '@angular/router';
import { UserRole, UserService } from './user.service';
import { inject } from '@angular/core';
import { map } from 'rxjs';

const defaultRoutes: { [key in UserRole]: any[] } = {
    'kyc-officer': ['ui', 'due-diligence-review'],
    'merchant-user': ['ui', 'dashboard'],
    admin: ['ui', 'admin-console'],
};

export const canMatchDefaultRoute: CanMatchFn = () => {
    const userService = inject(UserService);
    const router = inject(Router);
    return userService.roles$.pipe(
        map((roles) => {
            for (const role of roles) {
                if (defaultRoutes[role]) {
                    return router.createUrlTree(defaultRoutes[role]);
                }
            }
            return router.createUrlTree(['auth', 'login']);
        }),
    );
};
