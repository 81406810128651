import { inject } from '@angular/core';
import { CanMatchFn, Route } from '@angular/router';
import { FeatureFlag } from 'src/app/common/feature-flags';
import { FeatureFlagService } from './feature-flag.service';

export interface FeatureFlagData {
    featureFlags: FeatureFlag[];
}

export const featureFlagGuard: CanMatchFn = (route: Route): boolean => {
    const featureFlagService = inject(FeatureFlagService);
    return (
        (route.data as FeatureFlagData)?.featureFlags?.every((featureFlag) =>
            featureFlagService.isFeatureFlagEnabled(featureFlag),
        ) ?? true
    );
};
