import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { canMatchLoggedIn } from './shared/modules/auth/logged-in.guard';
import { FeatureFlagData, featureFlagGuard } from './shared/modules/feature-flag/feature-flag.guard';
import { FeatureFlag } from './common/feature-flags';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { canMatchLoggedOut } from './shared/modules/auth/logged-out.guard';
import { canMatchDefaultRoute } from './shared/modules/auth/default-route.guard';
import { RolesRouteData, canMatchRole } from './shared/modules/auth/role.guard';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.routes').then((mod) => mod.AUTH_ROUTES),
        canMatch: [canMatchLoggedOut],
    },
    {
        path: 'ui',
        loadChildren: () => import('./logged-in.routes').then((m) => m.LOGGED_IN_ROUTES),
        canMatch: [canMatchLoggedIn],
    },
    {
        path: 'kyc',
        title: 'Customer Due Diligence',
        loadChildren: () =>
            import('./features/customer-due-diligence/due-diligence-merchant-frame/merchant-due-diligence-routes').then(
                (r) => r.MERCHANT_DUE_DILIGENCE_ROUTES,
            ),
        canMatch: [canMatchLoggedIn, featureFlagGuard, canMatchRole],
        data: {
            featureFlags: [FeatureFlag.MerchantKyc],
            roles: ['merchant-user'],
        } as FeatureFlagData & RolesRouteData,
    },
    { path: 'login', pathMatch: 'full', redirectTo: 'auth' },
    { path: ':userId/verify', redirectTo: 'auth/:userId/verify' },
    { path: ':userId/password', redirectTo: 'auth/:userId/password' },
    { path: ':userId/reset-password', redirectTo: 'auth/:userId/reset-password' },
    { path: '', pathMatch: 'full', canMatch: [canMatchDefaultRoute], component: PageNotFoundComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
