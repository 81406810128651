import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NeoLogoComponent } from '@neonomics/neo-ui';
import { NeoButtonComponent } from 'src/app/shared/components/neo-button/neo-button.component';
import { NeoLinkComponent } from 'src/app/shared/components/neo-link/neo-link.component';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    imports: [NeoButtonComponent, NeoLinkComponent, NeoLogoComponent],
})
export class PageNotFoundComponent {
    constructor(private router: Router) {}

    public navigateToDashboard(): void {
        this.router.navigate(['/']);
    }
}
