<div class="header">
    <neo-logo class="logo"></neo-logo>
    <p>Merchant Portal</p>
</div>

<div class="page404">
    <div class="image-wrapper">
        <div class="image"></div>
    </div>
    <div class="content-wrapper">
        <div class="info-pane">
            <div class="status">404 error</div>
            <div class="header">Page not found</div>
            <p>Sorry, the page you are looking for doesn't exist.</p>
            <div class="button-wrapper">
                <app-neo-button
                    (clickEmitter)="navigateToDashboard()"
                    [text]="'Take me to the dashboard'"
                ></app-neo-button>
            </div>
        </div>
        <div class="links">
            <div class="link-wrapper">
                <app-neo-link [to]="'https://docs.neonomics.io'">
                    Documentation
                    <div class="arrow-fat"></div>
                </app-neo-link>
                <p>Dive in to learn all about our products.</p>
            </div>
            <div class="link-wrapper">
                <app-neo-link
                    [to]="'https://neonomics.atlassian.net/servicedesk/customer/portal/2'"
                    [openNewPage]="true"
                >
                    Reach out to support
                    <div class="arrow-fat"></div>
                </app-neo-link>
                <p>Our friendly team is here to help.</p>
            </div>
        </div>
    </div>
</div>
