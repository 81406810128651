import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private endpointsWithoutSession: string[] = ['/auth/login'];

    public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedReq = httpRequest;
        const URI = httpRequest.url.substring(environment.apiUrl.length);
        if (!this.endpointsWithoutSession.includes(URI)) {
            modifiedReq = httpRequest.clone({
                withCredentials: true,
            });
        }
        return next.handle(modifiedReq);
    }
}
