import { CanMatchFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';
import { UserService } from './user.service';

export const canMatchLoggedOut: CanMatchFn = () => {
    const router = inject(Router);
    const userService = inject(UserService);
    return userService.loggedIn$.pipe(
        first(),
        map((isLoggedIn) => {
            if (isLoggedIn) {
                return router.createUrlTree(['/']);
            } else {
                return true;
            }
        }),
    );
};
