import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot, ActivatedRouteSnapshot, PRIMARY_OUTLET } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class MerchantTitleStrategy extends TitleStrategy {
    constructor(private readonly title: Title) {
        super();
    }

    public override updateTitle(routerState: RouterStateSnapshot) {
        let title = 'Neonomics Merchant Portal';

        const titles = this.collectTitles(routerState);
        const routeTitle = titles.join(' | ');
        if (routeTitle) {
            title += ` | ${routeTitle}`;
        }

        this.title.setTitle(title);
    }

    // Walk the activated route tree like the built in TitleStrategy. But collect all the titles on the way.
    private collectTitles(snapshot: RouterStateSnapshot): string[] {
        const titles: string[] = [];
        let route: ActivatedRouteSnapshot | undefined = snapshot.root;
        while (route !== undefined) {
            const routeTitle = this.getResolvedTitleForRoute(route);
            // Lazy loaded routes are duplicated so we dedupe them
            if (routeTitle !== undefined && routeTitle !== titles[titles.length - 1]) {
                titles.push(routeTitle);
            }
            route = route.children.find((child) => child.outlet === PRIMARY_OUTLET);
        }
        return titles;
    }
}
