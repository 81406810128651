import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // If the url starts with http(s):// then we shouldn't add the baseUrl
        if (req.url.match(/^https?:\/\//)) {
            return next.handle(req);
        }

        const clonedReq = req.clone({
            url: `${environment.apiUrl}${req.url}`,
            withCredentials: true,
        });
        return next.handle(clonedReq);
    }
}
